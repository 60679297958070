import React, { useState } from "react";
import "./carousal.css"
import { BsFillArrowRightCircleFill,BsFillArrowLeftCircleFill} from "react-icons/bs";



const Carousal = ({SliderData}) => {
    const slide = SliderData;
    const [current, setCurrent] = useState(0);
    const length = slide.length;
  
    const nextSlide = () => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    };
  
    const prevSlide = () => {
      setCurrent(current === 0 ? length - 1 : current - 1);
    };
  
    if (!Array.isArray(slide) || slide.length <= 0) {
      return null;
    }
  return (
    <div className="carousal">
      <BsFillArrowLeftCircleFill className="left-arrow" onClick={prevSlide} />
      <BsFillArrowRightCircleFill className="right-arrow" onClick={nextSlide} />
      {SliderData.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
          >
            {index === current && (
               <>
               <div className="carousal-overlay"></div>
              <img className="carousal-img" src={slide.image} alt="carousal" />
              <div className="carousal-content">
            <h2 className="carousal-title" style={{"color":"#fff"}}>{slide.title}</h2>
            <p className="carousal-desp">{slide.desp}</p>
            {/* {slide.button} */}
            </div>
               
               </>
            )}
          </div>
        );
      })}
    </div>
  )
}

export default Carousal