import React, { useState, useEffect } from "react";
import { MdClose, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Link, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import headerLines from '../../images/header-lines.svg';
import CumuliILogo from '../../images/cumuli/cumuli.png'
import nav1 from '../../images/homepage/nav1.svg'
import nav2 from '../../images/homepage/nav2.svg'
import nav3 from '../../images/homepage/nav3.svg'
import nav4 from '../../images/homepage/nav4.svg'
import nav5 from '../../images/homepage/nav5.svg'
import nav6 from '../../images/homepage/nav6.svg'

const Header = ({ activeLink, setActiveLink ,logo}) => {
    const service = [
        { id: 0, label: "Cloud Managed Services", redirect: "/services/cloud", icon: nav1 },
        { id: 1, label: "Cybersecurity", redirect: '/services/cybersecurity', icon: nav2 },
        { id: 2, label: "Data and Analytics", redirect: '/services/analytics', icon: nav3 },
        { id: 4, label: "DevSecOps and SRE Service", redirect: "/services/devops", icon: nav4 },
        { id: 4, label: "Software Development Services", redirect: '/services/software-services', icon: nav5 },
        { id: 5, label: "Migrate and Modernize", redirect: '/services/migrate-modernize', icon: nav6 },
        { id: 6, label: "Digital Marketing", redirect: '/services/digital-marketing', icon: nav4 }
    ];

    const projects = [
        { id: 0, label: "Cumuli: Digital Future Pathways", redirect: "/projects/cumuli", icon: CumuliILogo },
    ];

    const [navActive, setNavActive] = useState(false)
    const [navProjectActive, setNavProjectActive] = useState(false)
    const [toggleNav, setToggleNav] = useState(false);
    const [isNavActive, setIsNavActive] = useState(false);
    const [isMobileChildDropdown, setIsMobileChildDropdown] = useState(false);
    const [isMobileProjectChildDropdown, setIsMobileProjectChildDropdown] = useState(false);
    const navigate = useNavigate()

    const handleScrollTop = () => window.scrollTo(0, 0);

    const handleNavLinkClick = (link) => {
        setActiveLink(link);
        if (link !== '/services' && link !== '/projects') handleScrollTop(false);
        if (link !== '/services' && link !== '/projects') setToggleNav(false);
        setNavActive(false);
        setNavProjectActive(false)
    };

    const handleServiceClick = () => {
        setActiveLink('/services');
    };

    const handleProjectClick = () => {
        setActiveLink('/projects');
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Adjust this value according to your layout
            let scrollVal = window.innerWidth < 767 ? 50 : 100;
            if (scrollPosition > scrollVal) {
                setIsNavActive(true);
            } else {
                setIsNavActive(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <img src={headerLines} className='header-lines' alt='test' />

            <div className={`mobile__header  ${isNavActive ? 'toggleNav' : ''} ${toggleNav ? 'active' : ''}`}>
                <Link onClick={() => {window.scrollTo(0, 0);setActiveLink('/')}} to='/'>
                    <img className='header__logo' src={logo} alt='logo' />
                </Link>
                <div className='left__header'>
                    <Link onClick={() => window.scrollTo(0, 0)} to="/contactus" className='contact-btn'>Contact Us</Link>
                    <div className={`menu ${toggleNav ? 'active' : ''}`}>
                        <FaBars className='open' onClick={() => { setToggleNav(!toggleNav); }} />
                    </div>
                </div>
            </div>

            <header className={`header  ${isNavActive ? 'toggleNav' : ''} ${toggleNav ? 'active' : ''}`} >
                <span className='close-icon' onClick={() => setToggleNav(!toggleNav)}><MdClose /></span>

                <div className='header__container'>
                    <Link to='/' onClick={() => handleNavLinkClick('/')}>
                        <img className='header__logo' src={logo} alt='logo' />
                    </Link>
                    <ul >
                        <li>
                            <Link to="/" className={`navlink ${activeLink === '/' ? "active" : ""}`} onClick={() => handleNavLinkClick('/')}>
                                Home
                            </Link>
                        </li>
                        <div className={`nav-drop ${isMobileChildDropdown ? "active" : ""} ${navActive ? "navActive" : ""}`}>
                            <div className={`drop-text navlink ${activeLink === '/services' ? "active" : ""} `}
                                onClick={() => {
                                    if (window.innerWidth <= 767) {
                                        setIsMobileChildDropdown(!isMobileChildDropdown);
                                        handleNavLinkClick('/services');
                                        setNavActive(!navActive)
                                    } else {
                                        navigate('/services')
                                        window.scrollTo(0, 0)
                                        setActiveLink('/services')
                                    }
                                }}
                            >
                                Services <div className="navIcon"><MdOutlineKeyboardArrowDown className="navIcon__arrow" /></div>
                            </div>
                            <div className={`drop-dropdown-container ${navActive ? 'navActive' : ''}`}>
                                <ul className={`drop-dropdown ${navActive ? 'navActive' : ''} `}>
                                    <span className='mob-close-icon'
                                        onClick={() => {
                                            setNavActive(false);
                                            setIsMobileChildDropdown(false)
                                        }}><MdClose /></span>
                                    {service?.map((element, index) => (
                                        <Link key={index} to={element.redirect} onClick={() => { handleNavLinkClick(element.redirect); handleServiceClick(); setIsMobileChildDropdown(false) }}>
                                            <img src={element.icon} alt="nimbusbytes" /><span>{element.label}</span>
                                        </Link>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className={`nav-drop ${isMobileProjectChildDropdown ? "active" : ""} nav-projects ${navProjectActive ? "navActive" : ""}`}>
                            <div className={`drop-text navlink ${activeLink === '/projects' ? "active" : ""} `}
                                onClick={() => {
                                    if (window.innerWidth <= 767) {
                                        setIsMobileProjectChildDropdown(!isMobileProjectChildDropdown);
                                        handleNavLinkClick('/projects');
                                        setNavProjectActive(!navProjectActive)
                                    } else {
                                        navigate('/projects')
                                        window.scrollTo(0, 0)
                                        setActiveLink('/projects')
                                    }
                                }}
                            >
                                Projects <div className="navIcon"><MdOutlineKeyboardArrowDown className="navIcon__arrow" /></div>
                            </div>
                            <div className={`drop-dropdown-container ${navProjectActive ? 'navActive' : ''}`}>
                                <ul className={`drop-dropdown ${navProjectActive ? 'navActive' : ''} `}>
                                    <span className='mob-close-icon'
                                        onClick={() => {
                                            setNavProjectActive(false);
                                            setIsMobileProjectChildDropdown(false)
                                        }}><MdClose /></span>
                                    {projects?.map((element, index) => (
                                        <Link key={index} to={element.redirect} onClick={() => { handleNavLinkClick(element.redirect); handleProjectClick(); setIsMobileProjectChildDropdown(false) }}>
                                            <img src={element.icon} alt="nimbusbytes" /><span>{element.label}</span>
                                        </Link>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <li>
                            <Link to="/about" className={`navlink ${activeLink === '/about' ? "active" : ""}`} onClick={() => handleNavLinkClick('/about')}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to='/our-team' className={`navlink ${activeLink === '/our-team' ? "active" : ""}`} onClick={() => handleNavLinkClick('/our-team')}>
                                Meet Our Team
                            </Link>
                        </li>
                        <li>
                            <Link to='/faq' className={`navlink ${activeLink === '/faq' ? "active" : ""}`} onClick={() => handleNavLinkClick('/faq')}>
                                FAQ
                            </Link>
                        </li>
                        <li>
                            <Link to='/contactus' className='contact-btn' onClick={() => { handleNavLinkClick('/contactus'); }}>
                                {window.innerWidth > 768 ? "Contact Us" : "Get In Touch"}
                            </Link>
                        </li>
                    </ul>
                </div>
            </header>
            <div className={`overlay ${toggleNav ? 'active' : ''}`}></div>

        </>
    );
}

export default Header;
